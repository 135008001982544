// Home page | en
export const HOME_PAGE_EN_ROUTE = '/en';
export const HOME_PAGE_EN_NAME = 'Home';

// Home page | ar
export const HOME_PAGE_AR_ROUTE = '/ar';
export const HOME_PAGE_AR_NAME = 'الرئيسية';

// Privacy page | en
export const PRIVACY_PAGE_EN_ROUTE = '/en/privacy';
export const PRIVACY_PAGE_EN_NAME = 'Privacy Policy | Disty';

// Privacy page | ar
export const PRIVACY_PAGE_AR_ROUTE = '/ar/privacy';
export const PRIVACY_PAGE_AR_NAME = 'سياسة الخصوصية | دستي';

// Terms and Conditions page | en
export const TERMS_AND_CONDITIONS_PAGE_EN_ROUTE = '/en/terms';
export const TERMS_AND_CONDITIONS_PAGE_EN_NAME = 'Terms and Conditions | Disty';

// Terms and Conditions page | ar
export const TERMS_AND_CONDITIONS_PAGE_AR_ROUTE = '/ar/terms';
export const TERMS_AND_CONDITIONS_PAGE_AR_NAME = 'الأحكام والشروط | دستي';
